"use client";

import { useMemo } from "react";
import { Box, BoxProps, Link, LinkProps, SxProps } from "@mui/material";

import { MhcAttributionFragment } from "graphqlApi/types";
import { StatAttributions } from "modules/Topics/util/elementHelpers/dashboard/types";

import { AttributionPopoverBase } from "common/components/AttributionPopoverBase";

export interface AttributionPopoverProps extends BoxProps {
  id?: string;
  attributions: Array<MhcAttributionFragment> | StatAttributions;
  overrideTitle?: string;
  overrideColor?: boolean;
  linkSx?: SxProps;
  linkVariant?: LinkProps["variant"];
  uiLocation?: string;
}

export const AttributionPopover: React.FC<AttributionPopoverProps> = ({
  id = "about-the-data-popup",
  attributions,
  overrideTitle,
  overrideColor,
  linkSx,
  linkVariant = "body2",
  uiLocation,
  ...props
}) => {
  const title = useMemo(() => (overrideTitle ? overrideTitle : "About the Data"), [overrideTitle]);
  return (
    <Box {...props}>
      <AttributionPopoverBase id={id} attributions={attributions ?? []} uiLocation={uiLocation}>
        <Link
          variant={linkVariant}
          sx={linkSx}
          color={overrideColor ? undefined : "textSecondary"}
          component="button"
        >
          {title}
        </Link>
      </AttributionPopoverBase>
    </Box>
  );
};
