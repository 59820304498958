import { Box, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import some from "lodash/some";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { capitalizeFirstLetter } from "common/util/capitalizeFirstLetter";
import { formatValueByUnit } from "common/util/formatHelpers";
import calculatePercentChange from "common/util/percentChange";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { formatDateTime } from "../ReportDates/ReportDates";

export interface KpiPercentSentenceProps {
  firstValue: number | null | undefined;
  lastValue: number | null | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  precision?: number;
  granularity?: MhcTimeSeriesGranularityEnum;
}

interface Args extends KpiPercentSentenceProps {
  percentChange?: number;
  capitalizePercentChange?: boolean;
  capitalize?: boolean;
  pastTense?: boolean;
  includeDateRange?: boolean;
  valueFirst?: boolean;
}

export const generateChangePhrase = ({
  firstValue,
  lastValue,
  startDate,
  endDate,
  precision,
  granularity,
  capitalizePercentChange = true,
  capitalize = true,
  pastTense = true,
  includeDateRange = true,
  valueFirst = false
}: Args): string | null => {
  if (some([isNil(lastValue), isNil(firstValue), isNil(startDate), isNil(endDate)])) {
    return null;
  }
  let dateRange = "";

  if (includeDateRange) {
    const _startDate = getUtcDateFromString(startDate);
    const _endDate = getUtcDateFromString(endDate);
    if (!_startDate || !_endDate) {
      return null;
    }
    dateRange = `from ${formatDateTime(_startDate, granularity) ?? ""} to ${
      formatDateTime(_endDate, granularity) ?? ""
    }`;
  }
  // Null/undefined is checked by some(isNil...) above
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const percentChange = calculatePercentChange({ firstValue, lastValue });

  const directionWords = {
    decrease: pastTense ? "decreased" : "decrease",
    increase: pastTense ? "increased" : "increase"
  };

  const formattedPercentChange = formatValueByUnit({
    value: Math.abs(percentChange ?? 0),
    unit: "percent",
    precision,
    isPercent: true
  });
  const percentChangePhrase =
    !isNil(percentChange) && percentChange !== 0 ? ` ${formattedPercentChange}` : "";

  const increasedDecreased =
    percentChange === 0
      ? "Did not change"
      : (percentChange ?? 0) < 0
      ? directionWords.decrease
      : directionWords.increase;

  capitalizePercentChange && capitalizeFirstLetter(increasedDecreased);

  let fullStatement = `${increasedDecreased}${percentChangePhrase} ${dateRange}`.trim();
  if (valueFirst) {
    fullStatement = `${percentChangePhrase} ${increasedDecreased} ${dateRange}`.trim();
  }
  if (capitalize) {
    return capitalizeFirstLetter(fullStatement);
  }
  return fullStatement;
};

export const KpiPercentSentence = ({
  firstValue,
  lastValue,
  startDate,
  endDate,
  precision,
  granularity
}: KpiPercentSentenceProps) => {
  if (
    some([isNil(lastValue), isNil(firstValue), firstValue === 0, isNil(startDate), isNil(endDate)])
  ) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%"
      }}
    >
      <Typography
        variant="body2"
        component="div"
        sx={{
          flexGrow: 0,
          flexShrink: 1,
          lineHeight: "20px"
        }}
      >
        {generateChangePhrase({
          firstValue,
          lastValue,
          startDate,
          endDate,
          precision,
          granularity
        })}
      </Typography>
    </Box>
  );
};
