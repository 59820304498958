import { ReactNode } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import compact from "lodash/compact";

import { MhcTarget, MhcTargetFragment } from "graphqlApi/types";

import { LinkOrText } from "common/components/LinkOrText";

export interface TargetTableProps {
  target: MhcTarget | MhcTargetFragment;
  formattedValue?: string;
  subtitle: string;
  progress?: string;
}

type TargetTableRow = [string, string | ReactNode];

export const TargetTable: React.FC<TargetTableProps> = ({
  target,
  formattedValue,
  subtitle,
  progress
}) => {
  const { dataSource, referenceUrl, value, url } = target;
  const rows: TargetTableRow[] = compact([
    [
      "Target Value",
      <Typography key="target-value" display="flex" gap="3px" alignItems="baseline">
        <Typography component="span">{formattedValue || value}</Typography>
        <Typography component="span" variant="body3">
          {subtitle}
        </Typography>
      </Typography>
    ],
    ["Description", <Typography key="description">{target.description}</Typography>],
    progress && ["Progress", <Typography key="progress">{progress}</Typography>],
    dataSource && [
      "Data Source",
      <LinkOrText
        key="data-source"
        renderAsLink={!!referenceUrl}
        href={referenceUrl ?? ""}
        text={dataSource ?? ""}
      />
    ]
  ]);
  return (
    <Table>
      <TableBody>
        {rows.map(([name, cellContent], i) => {
          const isFirstRow = i === 0;
          const cellStyle = {
            borderWidth: isFirstRow ? 1 : 0,
            fontSize: 14,
            px: 0,
            py: 0.25,
            pt: i === 1 ? 1.5 : undefined,
            pb: isFirstRow ? 1.5 : undefined
          };
          return (
            <TableRow key={name.toLowerCase().replace(" ", "-")}>
              <TableCell
                sx={{
                  ...cellStyle,
                  width: { md: "25%", lg: "10%" }
                }}
              >
                <Typography
                  sx={{
                    fontSize: isFirstRow ? 16 : 14,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    pr: 4
                  }}
                >
                  {name}
                </Typography>
              </TableCell>
              <TableCell sx={{ ...cellStyle }}>
                <Stack direction="row" justifyContent="space-between" sx={{ pl: 0.5 }}>
                  {cellContent}
                  {isFirstRow && url && (
                    <Link
                      variant="body2"
                      key="url"
                      href={url}
                      target="_blank"
                      sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                    >
                      <OpenInNewIcon fontSize="inherit" />
                      View more information
                    </Link>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
