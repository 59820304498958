import React from "react";
import { Box, Typography, TypographyProps } from "@mui/material";
import isNil from "lodash/isNil";

import { theme } from "theme/theme";

import { SuppressionTooltip } from "../SuppressionTooltip/SuppressionTooltip";
import { KpiProps } from ".";

export type KpiValueSize = "x-large" | "large" | "medium" | "small";

export interface KpiValueProps {
  value: KpiProps["value"];
  caption?: KpiProps["statCaption"];
  size: KpiValueSize;
  kpiFillsContainer?: boolean;
  isSmall?: boolean;
}

const KpiValue: React.FC<KpiValueProps> = ({ value, caption, size, kpiFillsContainer }) => {
  let fontSize;
  let valueVariant: TypographyProps["variant"] = "h4";
  const isSmall = !size.match(/large|medium/);
  if (!isSmall) {
    valueVariant = size === "medium" ? "h3" : "h1";
  }
  if (size === "x-large") {
    fontSize = { xs: "32px", sm: "38px", md: "42px", lg: "50px" };
  }
  const valueProps: Pick<TypographyProps, "variant" | "sx"> = {
    variant: valueVariant,
    sx: {
      fontSize,
      fontWeight: isSmall ? theme.typography.fontWeightMedium : 400,
      color: isSmall ? undefined : "#245194",
      flex: kpiFillsContainer ? "1 1 40%" : undefined
    }
  };
  return (
    <Box data-testid={"KpiValueTestId"} sx={{ my: "auto" }}>
      <Typography component="div" {...valueProps} display="flex" alignItems="center">
        {value ?? <SuppressionTooltip />}
      </Typography>
      {!isNil(value) && caption && (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          sx={({ palette }) => ({
            flexGrow: 0,
            flexShrink: 1,
            color: palette.component.textColor
          })}
        >
          {caption}
        </Typography>
      )}
    </Box>
  );
};

export default KpiValue;
