import compact from "lodash/compact";

import { MhcStatImprovementEnum } from "graphqlApi/types";

import { IconId, IconIdEnum } from "../../Icons/utils";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getStatTypeByStatId } from "modules/Topics/util/fetchingFunctions/kpi/util";

import { KpiTargetProps } from ".";

type TargetValue = string | number;

export const getChangePhrase = (isImproving: boolean | undefined | null): string => {
  switch (isImproving) {
    case true:
      return "Improving";
    case false:
      return "Getting worse";
    case null:
      return "No Change";
    case undefined:
      return "Baseline";
    default:
      return "";
  }
};

export const lowerOrHigher = (improvement: MhcStatImprovementEnum, gettingWorse = true) => {
  const lowerIsBetter = improvement === MhcStatImprovementEnum.LowerIsBetter;
  if (gettingWorse) {
    return lowerIsBetter ? "higher" : "lower";
  }
  return lowerIsBetter ? "lower" : "higher";
};

export const determineProgressIcons = (statement: string): IconId[] => {
  const icons: IconId[] = [];
  const _statement = statement.toLowerCase();
  if (_statement.includes("not met") || _statement.includes("worse")) {
    icons.push(IconIdEnum.NeedsAttention);
  }
  if (_statement.includes("improving")) {
    icons.push(IconIdEnum.Improving);
  }
  if (_statement.includes("target met")) {
    icons.push(IconIdEnum.TargetMet);
  }
  return icons;
};

const getDateRangePhrase = ([startDate, endDate]: Date[]) =>
  startDate && endDate ? ` between ${startDate.getFullYear()} and ${endDate.getFullYear()}` : "";

const siSubtitleWithoutRate = (subtitle?: string | null, unit?: string | null) => {
  if (unit && unit === "percent") return "";
  return ` ${(subtitle || "").replace("rate", "").replace("% ", "")}`;
};

const getBaseLongStatement = ({
  statIdentifier,
  currentValue
}: Pick<KpiTargetProps, "currentValue" | "statIdentifier">) => {
  const { name, subtitle, id, unit, precision, isPercent, ageAdjusted } = statIdentifier;
  return `The ${getStatTypeByStatId(id, ageAdjusted ?? false, isPercent ?? false).toLowerCase()}
  for ${name} is ${formatValueByUnit({
    value: currentValue,
    unit,
    precision,
    isPercent
  })} ${siSubtitleWithoutRate(subtitle ?? "")}.`;
};

interface NeedsAttentionStatementArgs
  extends Omit<KpiTargetProps, "currentValue" | "target" | "dateRange"> {
  baseStatement?: string;
  dateRangePhrase: string;
  targetValue: TargetValue;
}

const getNeedsAttentionStatement = ({
  baseStatement = "",
  statIdentifier: { improvement, subtitle = "", unit = "" },
  targetValue,
  targetMet,
  isImproving,
  dateRangePhrase,
  locationName
}: NeedsAttentionStatementArgs) => {
  const lowerOrHigherString = lowerOrHigher(improvement as MhcStatImprovementEnum);
  const targetStatement = `This indicator has ${targetMet ? "" : "not "}met the target ${
    unit ?? ""
  } of ${targetValue} ${isImproving ? "but" : "and"} improvement has occurred${dateRangePhrase}.`;
  const needsAttentionStatement = `This indicator needs attention ${
    locationName ? `in ${locationName}` : ""
  } because the progress is getting worse${
    !targetMet
      ? ` and is ${lowerOrHigherString} than the target ${
          unit as string
        } of ${targetValue}${siSubtitleWithoutRate(subtitle, unit)}`
      : ""
  }.`;
  return ` ${baseStatement} ${targetMet ? targetStatement : needsAttentionStatement}`.trim();
};

const getTargetMetStatement = ({
  baseStatement,
  targetValue,
  targetMet,
  statIdentifier: { unit, subtitle }
}: {
  baseStatement: string;
  targetMet: KpiTargetProps["targetMet"];
  targetValue: TargetValue;
  statIdentifier: KpiTargetProps["statIdentifier"];
}) =>
  `${baseStatement} This indicator has ${targetMet ? "" : "not "}met the target ${
    unit ?? ""
  } of ${targetValue} ${siSubtitleWithoutRate(subtitle)}.`;

export const buildStatements = ({
  target,
  targetMet,
  isImproving,
  statIdentifier,
  statSentence,
  currentValue,
  locationName = "",
  dateRange
}: Omit<KpiTargetProps, "kpiFillsContainer">) => {
  const targetValue = target?.value;
  const changePhrase = getChangePhrase(isImproving);
  const dateRangePhrase = dateRange ? getDateRangePhrase(dateRange) : "";
  const baseLongStatement = statSentence ?? getBaseLongStatement({ statIdentifier, currentValue });

  if (targetValue == null) {
    let improvementPhrase = "";
    if (isImproving === true) improvementPhrase = `Improvement has occurred${dateRangePhrase}.`;
    if (!isImproving && changePhrase === "Getting worse") {
      improvementPhrase = `This indicator needs attention ${
        locationName ? `in ${locationName}` : ""
      } because the progress is getting worse.`;
    }
    return {
      changePhrase,
      shortStatement: compact([changePhrase]).join(", "),
      longStatement: `${baseLongStatement} ${improvementPhrase}`.trim()
    };
  }

  const formattedTargetValue = formatValueByUnit({
    value: target?.value ?? 0,
    unit: statIdentifier.unit,
    precision: statIdentifier.precision,
    isPercent: statIdentifier.isPercent
  });

  let longStatement = getTargetMetStatement({
    baseStatement: baseLongStatement,
    targetMet,
    targetValue: formattedTargetValue,
    statIdentifier
  });

  if (!targetMet && !isImproving) {
    longStatement = getNeedsAttentionStatement({
      baseStatement: baseLongStatement,
      statIdentifier,
      targetValue: formattedTargetValue,
      targetMet,
      isImproving,
      dateRangePhrase,
      locationName
    });
  }

  return {
    changePhrase,
    shortStatement: compact([changePhrase, targetMet ? "target met" : "target not met"]).join(", "),
    longStatement
  };
};
