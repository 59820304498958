import { Skeleton } from "@mui/material";

import { DEFAULT_SKELETON_ANIMATION, DEFAULT_SKELETON_STYLE } from "layout/configuration";

const ContentCardHeaderFallback = () => (
  <Skeleton
    height={40}
    sx={{ minWidth: 300, width: "25%", ...DEFAULT_SKELETON_STYLE }}
    variant="rectangular"
    animation={DEFAULT_SKELETON_ANIMATION}
  />
);

export default ContentCardHeaderFallback;
