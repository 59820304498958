/**
 * Compare two values and return a string indicating the relationship between the two of them.
 *
 * @param base - base number to use in comparison Ex. base (&lt;, &gt;, =) compare
 * @param compare - number to compare with base Ex. base (&lt;, &gt;, =) compare
 * @param greaterSmallerParticle - Particle for smaller and greater than comparisons
 * @param equalParticle - Particle for equality comparison
 *
 * @returns String showing the relation between base and compare
 */
export const lowerHigherWithParticle = (
  base: number,
  compare: number,
  greaterSmallerParticle = "than",
  equalParticle = "to"
) => {
  if (base < compare) {
    return `lower${greaterSmallerParticle ? ` ${greaterSmallerParticle}` : ""}`;
  }
  if (base > compare) {
    return `higher${greaterSmallerParticle ? ` ${greaterSmallerParticle}` : ""}`;
  }
  return `equal${equalParticle ? ` ${equalParticle}` : ""}`;
};
